$(function() {
  $("#fixed_asset_qr").keypress(function(event){
  		var keycode = (event.keyCode ? event.keyCode : event.which);
		if(keycode == '13'){
					scan_code 			= $("#fixed_asset_qr")[0].value		;
					path = "/audit_fixed_assets/save_scanned"	;
					$("#fixed_asset_qr")[0].value	= ""	;
					$.ajax({url: path,
							type: "post",
							data: {"read_code"	: scan_code},
							dataType: "json",

							success: function(result){
								notif(true)	;
							},

							error: function (jqXHR, text, errorThrown) {
							//alert(jqXHR.responseJSON.error);
							notif(false)	;
							}
					});
		}
	});
});