$(document).ready(function(){
  $("#myInput").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#myTable tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
});


$(document).ready(function(){
  $("#table_search").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("#table_row tr , .table_search_row tr , tbody tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });
});

$(document).ready(function(){
	$("#table_search_option").on("change",function(){
			var value = $(this).val().toLowerCase();
			$("#table_row tr").filter(function() {
				$(this).toggle($(this).children()[0].textContent.toLowerCase().indexOf(value) > -1);
		    });
	});
});


window.sortTable =	function(column_no) {
  var table, rows, switching, i, x, y, shouldSwitch;
  
  table = document.getElementsByClassName("table_body_sortable")[0];
  switching = true;
  /*Make a loop that will continue until
  no switching has been done:*/
  while (switching) {
    //start by saying: no switching is done:
    switching = false;
    rows = table.rows;
    /*Loop through all table rows (except the
    first, which contains table headers):*/
    for (i = 0; i < (rows.length); i++) {
      //start by saying there should be no switching:
      shouldSwitch = false;
      /*Get the two elements you want to compare,
      one from current row and one from the next:*/
      x = $(rows[i]).children()[column_no].innerHTML		;
      y = $(rows[i + 1]).children()[column_no].innerHTML	;
      
      x = parseInt(x)	;
      y = parseInt(y)	;
      
      //check if the two rows should switch place:
      if (x < y) {
        //if so, mark as a switch and break the loop:
        shouldSwitch = true;
        break;
      }
    }
    if (shouldSwitch) {
      /*If a switch has been marked, make the switch
      and mark that a switch has been done:*/
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
      switching = true;
    }
  }
};