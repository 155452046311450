$(function () {
	$('#hover_button').on('mouseover', function() {
		$('#hover_button').popover('show');	
	});
	  
	$('#hover_button').on('mouseout', function() {
		setTimeout(function() {
			$('#hover_button').popover('hide');	
		}, 200);
	});
 });