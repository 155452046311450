// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()				;
require("@rails/activestorage").start()		;
require("channels")							;
require("jquery")							;
require('webpack-jquery-ui')				;
require('webpack-jquery-ui/css')			;

require("packs/default")				;
require("packs/spare_audit")			;
require("packs/delivery_challan")		;
require("packs/sale_return")			;
require("packs/purchase_invoice")		;
require("packs/attendance")				;
require("packs/lost_sale")				;
require("packs/physical_delivery")		;
require("packs/employee_task")			;
require("packs/qr_code")				;
require("packs/spare_part")				;
require("packs/container")				;
require("packs/party_order")			;
require("packs/shoppy")					;
require("packs/party_part_discount")	;
require("packs/pinncale")				;
require("packs/party_followup")			;
require("packs/cash_deposit")			;
require("packs/payment_receipts")		;
require("packs/ta_da")					;
require("packs/spare_transaction")		;
require("packs/ledger")		;
require("packs/system_spare_part")		;

require("packs/json_request")			;
require("packs/geo_location")			;

require("packs/qr_read_camera")			;
require("packs/tables")					;
require("packs/audit_fixed_assets")		;
require("packs/documentations")			;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'bootstrap'
import 'bootstrap-select'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "chart.js"
import "color-calendar/dist/css/theme-glass.css"
